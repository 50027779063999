import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { FormControlLabel, FormLabel } from "@material-ui/core";
import { useEffect, useState } from "react";

import ActivitiesBlock from "../../blocks/ActivitiesBlock";
import Activity from "../../services/Activities/Activity";
import Checkbox from "@mui/material/Checkbox";
import ComputeAllocationWalletResponse from "../../services/Users/Models/ComputeAllocationResponse";
import { DatePicker } from "@mui/x-date-pickers";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import Field from "../../services/DynamicEntity/Field";
import { FieldType } from "../../services/DynamicEntity/FieldType";
import FormatHelpers from "../../services/FormatHelpers";
import LoadingComponent from "../../components/LoadingComponent";
import { LoadingStatus } from "../../contexts/LoadingStatus";
import { Notify } from "../../components/notify";
import OrganizationFeature from "../../services/Organizations/OrganizationFeature";
import OrganizationHelpers from "../../services/Organizations/OrganizationHelpers";
import PageHeader from "../../components/PageHeader";
import PageSection from "../../components/PageSection";
import PageToolbar from "../../components/PageToolbar";
import RolesHelpers from "../../services/Users/RolesHelpers";
import User from "../../services/Users/User";
import UserTransactionsBlock from "../../blocks/UserTransactionsBlock";
import dayjs from "dayjs";
import i18n from "../../services/i18n";
import { t } from "i18next";
import { useParams } from "react-router-dom";
import { useSessionContext } from "../../contexts/SessionContext";
import { useTranslation } from "react-i18next";
import userService from "../../services/Users/UserService";

function WorkindTextField(props: any) {
  return <TextField variant="standard" size="small" {...props} />;
}

export default function UserProfilePage() {
  const { t } = useTranslation();
  const { user, organizationHasFeature } = useSessionContext();
  const { organizationId, idUser } = useParams<string>();
  const lang = localStorage.getItem("language") || "en";

  const [loading, setLoading] = useState<LoadingStatus>(LoadingStatus.Loading);
  const [userProfile, setUserProfile] = useState<User | undefined>(undefined);
  const [userActivities, setUserActivities] = useState<Array<Activity>>([]);

  const [selectedUserId, setSelectedUserId] = useState(0);
  const [selectedUserOrg, setSelectedUserOrg] = useState(0);
  const [activateOrDeactivateUser, setActivateOrDeactivateUser] = useState(-1);

  const [openDialogConfirm, setOpenDialogConfirm] = useState(false);
  const [dialogTitleConfirm, setDialogTitle] = useState("");
  const [dialogContentConfirm, setDialogContent] = useState("");

  const [computeAllocationResult, setComputeAllocationResult] = useState<ComputeAllocationWalletResponse[]>([]);
  const [computeAllocationOpen, setComputeAllocationOpen] = useState<boolean>(false);

  const [edit, setEdit] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState<string | undefined>();
  
  const [openDialogResetAllocation, setOpenDialogResetAllocation] = useState(false);

  const orgAdmin = user && RolesHelpers.IsOrganizationAdminFromList(user.roles);
  const workindAdmin = user && RolesHelpers.IsWorkindAdminFromList(user.roles);

  useEffect(() => {
    loadProfile();
  }, [user, idUser]);

  const handleLanguageChange = (event: SelectChangeEvent) => {
    const selectedLang = event.target.value as string;
    setSelectedLanguage(selectedLang);
    setUserProfile((prevUserProfile) => ({
      ...prevUserProfile!,
      language: selectedLang,
    }));
  };

  const handleClickOpenForActivate = (OrgId: number, Userid: number) => {
    setOpenDialogConfirm(true);
    setSelectedUserId(Userid);
    setSelectedUserOrg(OrgId);
    setActivateOrDeactivateUser(1);
    setDialogTitle(t("user.failed-dialogTitleActivate"));
    setDialogContent(t("user.failed-dialogContentActivate"));
  };

  const handleClickOpenForDeactivate = (OrgId: number, Userid: number) => {
    setOpenDialogConfirm(true);
    setSelectedUserId(Userid);
    setSelectedUserOrg(OrgId);
    setActivateOrDeactivateUser(2);
    setDialogTitle(t("user.failed-dialogTitleDeactivate"));
    setDialogContent(t("user.failed-dialogContentDeactivate"));
  };

  const handleCloseDialogConfirm = () => {
    setOpenDialogConfirm(false);
    setActivateOrDeactivateUser(-1);
  };

  const handleConfirmDialogActivate = async () => {
    await ActivateUser(selectedUserOrg, selectedUserId);
    handleCloseDialogConfirm();
  };

  const handleConfirmDialogDesactivate = async () => {
    await DeactivateUser(selectedUserOrg, selectedUserId);
    handleCloseDialogConfirm();
  };

  const DeactivateUser = async (idOrg: number, Iduser: number) => {
    const result = await userService.DeactivateUserProfile(idOrg, Iduser);
    if (result !== undefined) loadProfile();
  };

  const ActivateUser = async (idOrg: number, Iduser: number) => {
    const result = await userService.ActivateUserProfile(idOrg, Iduser);
    if (result !== undefined) loadProfile();
  };

  const handleTransactionCreated = async () => {
    await loadProfile();
  };

  if (loading === LoadingStatus.Loading) {
    return <LoadingComponent />;
  }
  if (!userProfile) {
    return t("admin.user-notfound");
  }

  const orgId = organizationId ? parseInt(organizationId) : -1;

  async function loadProfile() {
    try {
      if (user && organizationId && idUser) {
        if (!workindAdmin && user.organizations.length <= 0) {
          setLoading(LoadingStatus.Failure);
        } else {
          setLoading(LoadingStatus.Loading);

          const orgId = parseInt(organizationId);
          console.log(`Load(orgId=${orgId}, userId=${idUser})`);

          const profile = await userService.getUserProfile(orgId, idUser);
          setUserProfile(profile);
          setSelectedLanguage(profile?.language === "english" ? "en" : "fr");
          const activities = await userService.getUserActivities(orgId, parseInt(idUser));
          setUserActivities(activities);

          setLoading(user ? LoadingStatus.Success : LoadingStatus.Failure);
        }
      }
    } catch (e) {
      setLoading(LoadingStatus.Failure);
      Notify.error(t("admin.users.error-loading"));
    }
  }

  async function resetAllocation() {
    if (userProfile)
    {
      const res = await userService.updateAllocation(userProfile.organizationId, userProfile.id);
      if (!res) {
        Notify.error(t("admin.employee-profile.error-reset"));
      } else {
        loadProfile();
        setOpenDialogResetAllocation(false);
      }
    }
      
  }

  const org = user?.organizations.find((o) => o.id === orgId);
  const hasReferenceId = org?.useReferenceIdHasExternalKey ?? false;
  const userTable = org ? OrganizationHelpers.getOrganizationUserTable(org) : undefined;
  const userTableFields = userTable ? userTable.fields : new Map<string, Field>();

  const hasBankReimbursement = user && organizationHasFeature(orgId, OrganizationFeature.BankReimbursement);
  const useWallet = orgId && organizationHasFeature(orgId, OrganizationFeature.UseAccountWallet);

  const handleClickOpenDialogResetAllocation = () => {
    setOpenDialogResetAllocation(true);
  };

  const handleCloseDialogResetAllocation = () => {
    setOpenDialogResetAllocation(false);
  };

  return (
    <>
      <Dialog open={openDialogConfirm} onClose={handleCloseDialogConfirm} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{dialogTitleConfirm}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{dialogContentConfirm}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialogConfirm}>{t("common.no")}</Button>
          {activateOrDeactivateUser === 1 && (
            <>
              <Button onClick={handleConfirmDialogActivate} autoFocus>
                {t("common.yes")}
              </Button>
            </>
          )}
          {activateOrDeactivateUser === 2 && (
            <>
              <Button onClick={handleConfirmDialogDesactivate} autoFocus>
                {t("common.yes")}
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>

      <PageHeader returnUrl={`/admin/users/${orgId}`} title={t("admin.user-profile.title")} />

      <PageToolbar>
        {userProfile?.enabled === 0 && (
          <Button
            sx={{ minWidth: 100 }}
            variant="contained"
            color="primary"
            size="small"
            onClick={() => handleClickOpenForActivate(userProfile.organizationId, userProfile.id)}
          >
            {t("user.failed-userActivate")}
          </Button>
        )}
        {userProfile?.enabled === 1 && (
          <Button
            sx={{ minWidth: 100 }}
            variant="contained"
            color="primary"
            size="small"
            onClick={() => handleClickOpenForDeactivate(userProfile.organizationId, userProfile.id)}
          >
            {t("user.failed-userDeactivate")}
          </Button>
        )}

        {useWallet && (
          <Button sx={{ marginLeft: 1, minWidth: 100 }} variant="contained" color="primary" size="small" onClick={() => computeAllocation()}>
            {t("user.compute-allocation-label")}
          </Button>
        )}
        {orgAdmin && useWallet && (
             <Button sx={{ marginLeft: 1, minWidth: 100 }} variant="contained" color="primary" size="small" onClick={handleClickOpenDialogResetAllocation}>
               {t("admin.employee-profile.label-reset")}
             </Button>

     )}
      </PageToolbar>

      {/* Informations */}
      <PageSection title={t("common.info")}>
        <Grid container spacing={2} marginBottom={2}>
          <Grid item lg={3}>
            <WorkindTextField label={t("user.field-id")} disabled value={userProfile?.id} fullWidth />
          </Grid>
          {hasReferenceId && (
            <Grid item lg={3}>
              <WorkindTextField label={t("user.field-referenceid")} disabled value={userProfile?.referenceId} fullWidth />
            </Grid>
          )}
          <Grid item lg={3}>
            <WorkindTextField label={t("user.failed-organizationName")} disabled value={userProfile?.organizationName} fullWidth />
          </Grid>
        </Grid>

        <Grid container spacing={2} marginTop={2}>
          <Grid item lg={3}>
            <WorkindTextField
              label={t("user.field-firstname")}
              disabled={!edit}
              value={userProfile?.firstname}
              onChange={(e: any) => setUserProfile({ ...userProfile, firstname: e.target.value })}
              fullWidth
            />
          </Grid>
          <Grid item lg={3}>
            <WorkindTextField
              label={t("user.field-lastname")}
              disabled={!edit}
              value={userProfile?.lastname}
              onChange={(e: any) => setUserProfile({ ...userProfile, lastname: e.target.value })}
              fullWidth
            />
          </Grid>
          <Grid item lg={3}>
            <WorkindTextField
              label={t("user.field-email")}
              disabled={!edit}
              value={userProfile?.email}
              onChange={(e: any) => setUserProfile({ ...userProfile, email: e.target.value })}
              required
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} marginTop={2}>
          <Grid item lg={3}>
            <FormControl sx={{ minWidth: 300 }} size="medium">
              <Box>
                <InputLabel id="user-lang-label">{t("user.failed-userLang")}</InputLabel>
                <Select
                  labelId="user-lang-label"
                  id="user-lang"
                  disabled={!edit}
                  value={selectedLanguage}
                  label={t("user.failed-userLang")}
                  onChange={handleLanguageChange}
                  sx={{ minWidth: 240 }}
                >
                  <MenuItem value="en">{t("common.english")}</MenuItem>
                  <MenuItem value="fr">{t("common.french")}</MenuItem>
                </Select>
              </Box>
            </FormControl>
          </Grid>
          <Grid item lg={3}>
            <WorkindTextField
              label={t("user.failed-userMobile")}
              disabled={!edit}
              value={userProfile?.mobile}
              onChange={(e: any) => setUserProfile({ ...userProfile, mobile: e.target.value })}
              fullWidth
            />
          </Grid>
        </Grid>

        {/* Attributes */}
        {userTable && userTableFields && (
          <Grid container spacing={2} marginTop={2}>
            {Array.from(userTable.fields.keys()).map((key, i) => {
              const field = userTableFields.get(key);
              const value = userProfile.attributes.get(key);

              return (
                <Grid item lg={3} key={i}>
                  {(field?.type === FieldType.Text || field?.type === FieldType.Number || field?.type === FieldType.Boolean) && (
                    <TextField
                      variant="standard"
                      size="small"
                      value={value}
                      label={field?.display[lang]}
                      disabled={!edit}
                      onChange={(e: any) => {
                        const newValue = e.target.value;
                        setUserProfile(() => ({
                          ...userProfile,
                          attributes: new Map(userProfile.attributes.set(key, newValue)),
                        }));
                      }}
                      fullWidth
                    />
                  )}
                  {field?.type == FieldType.Datetime && (
                    <DatePicker
                      value={value ? dayjs(value) : null}
                      disabled={!edit}
                      label={field?.display[lang]}
                      onChange={(newValue) =>
                        setUserProfile(() => ({
                          ...userProfile,
                          attributes: new Map(userProfile.attributes.set(key, newValue!.format("YYYY-MM-DD HH:mm:ss"))),
                        }))
                      }
                    />
                  )}
                </Grid>
              );
            })}
          </Grid>
        )}

        <Grid container spacing={2} marginTop={2}>
          <Grid item lg={3}>
            <DateTimePicker label={t("user.failed-userUpdateAt")} ampm={false} disabled defaultValue={userProfile?.updatedAt} disableOpenPicker />
          </Grid>
          {userProfile?.lastLogin !== undefined && (
            <Grid item lg={3}>
              <DateTimePicker label={t("user.failed-userLastLogin")} ampm={false} disabled defaultValue={userProfile?.lastLogin} disableOpenPicker />
            </Grid>
          )}
          {userProfile?.disabledDate !== undefined && (
            <Grid item lg={3}>
              <DateTimePicker label={t("user.failed-userDisabledDate")} ampm={false} disabled value={userProfile?.disabledDate} disableOpenPicker />
            </Grid>
          )}
          {userProfile?.expirationDate !== undefined && (
            <Grid item lg={3}>
              <DateTimePicker label={t("user.failed-userExpirationDate")} disabled ampm={false} value={userProfile?.expirationDate} disableOpenPicker />
            </Grid>
          )}
        </Grid>

        <Grid container spacing={1} marginTop={2}>
          <Grid item lg={3}>
            <FormControl variant="standard" size="small" disabled>
              <FormLabel>
                {userProfile?.enabled ? (
                  <FormControlLabel control={<Checkbox defaultChecked />} label={t("user.failed-useraccountActivated")} />
                ) : (
                  <FormControlLabel control={<Checkbox />} label={t("user.failed-useraccountActivated")} />
                )}
              </FormLabel>
            </FormControl>
          </Grid>

          <Grid item lg={3}>
            <FormControl variant="standard" size="small" disabled>
              <FormLabel>
                {userProfile?.propfileCompleted ? (
                  <FormControlLabel control={<Checkbox defaultChecked />} label={t("user.failed-userPropfileCompleted")} />
                ) : (
                  <FormControlLabel control={<Checkbox />} label={t("user.failed-userPropfileCompleted")} />
                )}
              </FormLabel>
            </FormControl>
          </Grid>

          {hasBankReimbursement && (
            <Grid item lg={3}>
              <FormControl variant="standard" size="small" disabled>
                <FormLabel>
                  <FormControlLabel control={<Checkbox defaultChecked={userProfile?.bankInfoConfigured} />} label={t("user.field-bankInfoConfigured")} />
                </FormLabel>
              </FormControl>
            </Grid>
          )}
        </Grid>
        <Grid container spacing={2} paddingTop={2}>
          {edit && (
            <>
              <Grid item>
                <Button variant="contained" onClick={updateUserProfile}>
                  {t("common.save")}
                </Button>
              </Grid>
              <Grid item>
                <Button variant="outlined" onClick={cancelEmployeeProfile}>
                  {t("common.cancel")}
                </Button>
              </Grid>
            </>
          )}
          {!edit && (
            <Grid item>
              <Button variant="contained" onClick={() => setEdit(true)}>
                {t("common.edit")}
              </Button>
            </Grid>
          )}
        </Grid>
      </PageSection>

      {/* Address */}
      <PageSection title={t("common.addresses")}>
        <TableContainer>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>{t("user.failed-userAddress")}</TableCell>
                <TableCell>{t("user.failed-userAddress2")}</TableCell>
                <TableCell>{t("user.failed-userCity")}</TableCell>
                <TableCell>{t("user.failed-userProvince")}</TableCell>
                <TableCell>{t("user.failed-userPostalCode")}</TableCell>
                <TableCell>{t("user.failed-userCountry")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userProfile?.addresses.map((row) => (
                <>
                  <TableCell>{row.address}</TableCell>
                  <TableCell>{row.address2}</TableCell>
                  <TableCell>{row.city}</TableCell>
                  <TableCell>{row.province}</TableCell>
                  <TableCell>{row.postalCode}</TableCell>
                  <TableCell>{row.country}</TableCell>
                </>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </PageSection>

      {/* Transactions */}
      {userProfile && org && <UserTransactionsBlock user={userProfile} organization={org} onTransactionCreated={handleTransactionCreated} />}

      {/* Activities */}
      {orgAdmin && <ActivitiesBlock activities={userActivities} />}

      <DialogComputeAllocation open={computeAllocationOpen} results={computeAllocationResult} onClose={() => setComputeAllocationOpen(false)} />

      <Dialog
               open={openDialogResetAllocation}
               onClose={handleCloseDialogResetAllocation}
               aria-labelledby="alert-dialog-title"
               aria-describedby="alert-dialog-description"
             >
               <DialogTitle id="alert-dialog-title">{t("admin.employee-profile.label-resetAllocationLabel")}</DialogTitle>
               <DialogContent>
                 <DialogContentText id="alert-dialog-description">{t("admin.employee-profile.label-resetAllocationMsg")}</DialogContentText>
               </DialogContent>
               <DialogActions>
                 <Button onClick={handleCloseDialogResetAllocation}>{t("admin.employee-profile.label-cancelBtn")}</Button>
                 <Button onClick={resetAllocation} autoFocus>
                   {t("admin.employee-profile.lable-YesBtn")}
                 </Button>
               </DialogActions>
             </Dialog>
    </>
  );

  async function updateUserProfile() {
    if (!userProfile) return;

    if (!userProfile.email || userProfile.email.length === 0) {
      Notify.error(t("admin.employee-profile.email-required"));
      return;
    }
    if (!userProfile.firstname || userProfile.firstname.length === 0) {
      Notify.error(t("admin.employee-profile.firstname-required"));
      return;
    }
    if (!userProfile.lastname || userProfile.lastname.length === 0) {
      Notify.error(t("admin.employee-profile.lastname-required"));
      return;
    }

    try {
      setLoading(LoadingStatus.Loading);
      setEdit(false);

      await userService.updateUserProfile(orgId, userProfile);
    } catch (e) {
      setLoading(LoadingStatus.Failure);
      Notify.error(t("admin.users.error-loading"));
    } finally {
      setLoading(LoadingStatus.Success);
    }

    await loadProfile();
  }

  async function cancelEmployeeProfile() {
    setEdit(false);
  }

  async function computeAllocation() {
    if (!userProfile) return;

    setComputeAllocationResult([]);
    setComputeAllocationOpen(true);

    const res = await userService.computeAllocation(orgId, userProfile?.id);
    if (res === undefined) {
      Notify.error(t("admin.employee-profile.error-calc"));
      setComputeAllocationOpen(false);
    } else {
      setComputeAllocationResult(res);
    }
  }
}

function DialogComputeAllocation({ open, results, onClose }: { open: boolean; results: ComputeAllocationWalletResponse[]; onClose: () => void }) {
  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="compute-dialog-title" aria-describedby="compute-dialog-description">
      <DialogTitle id="compute-dialog-title">{t("user.dialog-compute.title")}</DialogTitle>
      <DialogContent>
        {results.map((result) => {
          const wa = result.walletAllocation;

          return (
            <Box mb={2}>
              <Typography fontWeight="bold">{result.organizationWalletDisplay[i18n.language]}</Typography>

              {result.message && <Typography>{result.message}</Typography>}

              {wa && (
                <Box pl={2}>
                  <Typography>
                    {t("user.dialog-compute.allocation")}: {FormatHelpers.formatMoney(wa.allocation)}
                  </Typography>
                  {wa.allocationOverflow > 0 && (
                    <Typography>
                      {t("user.dialog-compute.allocation-overflow")}: {FormatHelpers.formatMoney(wa.allocationOverflow)}
                    </Typography>
                  )}
                  <Typography pt={1}>
                    {t("user.dialog-compute.eligible")}: {wa.isEligible ? t("common.yes") : t("common.no")} - {FormatHelpers.formatDateShort(wa.eligibleDate)}
                  </Typography>
                  <Typography>
                    {t("user.dialog-compute.amount")}: {FormatHelpers.formatMoney(wa.amount)}
                  </Typography>
                  <Typography>
                    {t("user.dialog-compute.coeffiecient")}: {wa.coeffiecient.toFixed(2)}
                  </Typography>
                  <Typography>
                    {t("user.dialog-compute.prorata")}: {wa.prorata.toFixed(2)}
                  </Typography>
                </Box>
              )}
            </Box>
          );
        })}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t("common.close")}</Button>
      </DialogActions>
    </Dialog>
  );
}
