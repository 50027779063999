import Activity from "../Activities/Activity";
import Compute from "./Compute";
import ComputeAllocationRequest from "./Models/ComputeAllocationRequest";
import ComputeAllocationWalletResponse from "./Models/ComputeAllocationResponse";
import DemographicErrorResponse from "./DemographicErrorResponse";
import EmployeeProfile from "./EmployeeProfile";
import Field from "../DynamicEntity/Field";
import { ImportErrorResponse } from "./Models/ImportErrorResponse";
import ImportResponse from "./Models/ImportResponse";
import Me from "./Me";
import UpdateEmployeeProfile from "./UpdateEmployeeProfile";
import UpdateUserProfile from "./UpdateUserProfile";
import User from "./User";
import UserServiceDashboard from "./UserDashboardProfile";
import WalletLink from "../Organizations/WalletLink";
import WorkindApiClient from "../WorkindApiClient";
import axios from "axios";
import dayjs from "dayjs";
import mockUserService from "../../mock/mockUserService";

class UserService extends WorkindApiClient {
  async getMe(): Promise<Me | undefined> {
    if (process.env.REACT_APP_API_MOCK === "1") {
      return await mockUserService.getMe();
    } else {
      const me = await this.get<Me>(`${this.apiUrl}/users/me`);

      if (me) {
        for (const org of me.organizations) {
          for (const table of org.tables) {
            table.fields = new Map<string, Field>(Object.entries(table.fields));
          }
        }
      }

      return me;
    }
  }

  async getWalletLinks(organizationWalletId: string): Promise<Array<WalletLink>> {
    const list = await this.get<Array<WalletLink>>(`${this.apiUrl}/users/me/links?organizationWalletId=${organizationWalletId}`);
    return list || [];
  }

  async ActivateUserProfile(organizationId: number, userId: number): Promise<User | undefined> {
    try {
      return await this.post(`${this.apiUrl}/organizations/${organizationId}/users/${userId}/activate`, null);
    } catch {
      return undefined;
    }
  }

  async DeactivateUserProfile(organizationId: number, userId: number): Promise<User | undefined> {
    try {
      return await this.post(`${this.apiUrl}/organizations/${organizationId}/users/${userId}/deactivate`, null);
    } catch (error) {
      return undefined;
    }
  }

  async getUserDashboard(): Promise<UserServiceDashboard | undefined> {
    if (process.env.REACT_APP_API_MOCK === "1") {
      return await mockUserService.getUserDashboard();
    } else {
      return await this.get<UserServiceDashboard>(`${this.apiUrl}/users/me/dashboard`);
    }
  }

  async getUserProfile(organizationId: number, userId: string): Promise<User | undefined> {
    const profile = await this.get<User>(`${this.apiUrl}/organizations/${organizationId}/users/${userId}`);
    if (!profile) return undefined;

    return {
      ...profile,
      disabledDate: profile.disabledDate ? dayjs(profile.disabledDate) : undefined,
      expirationDate: profile.expirationDate ? dayjs(profile.expirationDate) : undefined,
      updatedAt: profile.updatedAt ? dayjs(profile.updatedAt) : undefined,
      lastLogin: profile.lastLogin ? dayjs(profile.lastLogin) : undefined,
      attributes: new Map<string, string>(Object.entries(profile.attributes)),
    };
  }

  async getUserActivities(organizationId: number, userId: number): Promise<Array<Activity>> {
    const activities = await this.get<Array<Activity>>(`${this.apiUrl}/organizations/${organizationId}/users/${userId}/activities`);
    if (!activities) return [];

    return activities.map((activity: Activity) => {
      return {
        ...activity,
        createdOn: new Date(activity.createdOn),
      };
    });
  }

  async addUserBankData(organizationId: string, userId: string, userBankData: any) {
    await this.put(`${this.apiUrl}/organizations/${organizationId}/users/${userId}/bankaccounts`, userBankData);
  }

  async getUsersOfOrganization(organizationId: number): Promise<Array<User>> {
    const users = await this.get<Array<User>>(`${this.apiUrl}/organizations/${organizationId}/users`);
    if (!users) return [];
    return users;
  }

  async updateUserProfile(organizationId: number, userProfile: User) {
    try {
      const attributesObject = Object.fromEntries(userProfile.attributes);
      const data: UpdateUserProfile = {
        userId: userProfile.id,
        email: userProfile.email,
        firstname: userProfile.firstname,
        lastname: userProfile.lastname,
        phoneNumber: userProfile.mobile,
        referenceId: userProfile.referenceId,
        language: userProfile.language,
        attributes: attributesObject,
      };
      await this.put(`${this.apiUrl}/organizations/${organizationId}/users`, data);
    } catch (error) {
      console.error("Error:", error);
    }
  }

  async getEmployeeProfilesOfOrganization(organizationId: number): Promise<Array<EmployeeProfile>> {
    const profiles = await this.get<Array<EmployeeProfile>>(`${this.apiUrl}/organizations/${organizationId}/users/employeeProfiles`);
    if (!profiles) return [];

    return profiles.map((profile) => {
      return {
        ...profile,
        employeeTypeDate: profile.employeeTypeDate ? dayjs(profile.employeeTypeDate) : undefined,
        statusDate: profile.statusDate ? dayjs(profile.statusDate) : undefined,
        hireDate: profile.hireDate ? dayjs(profile.hireDate) : undefined,
        payTypeDate: profile.payTypeDate ? dayjs(profile.payTypeDate) : undefined,
        jobCodeDate: profile.jobCodeDate ? dayjs(profile.jobCodeDate) : undefined,
      };
    });
  }

  async computeAllocation(organizationId: number, userId: number): Promise<ComputeAllocationWalletResponse[]> {
    try {
      const data: ComputeAllocationRequest = {
        userId: userId,
      };
      const response = await this.post<ComputeAllocationRequest, ComputeAllocationWalletResponse[]>(
        `${this.apiUrl}/organizations/${organizationId}/allocations/compute`,
        data
      );
      if (!response) return [];

      for (const r of response) {
        if (r.walletAllocation) {
          r.walletAllocation.eligibleDate = r.walletAllocation.eligibleDate ? new Date(r.walletAllocation.eligibleDate) : undefined;
        }
      }
      return response;
    } catch {
      return [];
    }
  }

  async updateAllocation(organizationId: number, userId: number): Promise<ComputeAllocationWalletResponse[]> {
    try {
      const data: ComputeAllocationRequest = {
        userId: userId,
      };
      const response = await this.post<ComputeAllocationRequest, ComputeAllocationWalletResponse[]>(
        `${this.apiUrl}/organizations/${organizationId}/allocations/update`,
        data
      );
      if (!response) return [];

      for (const r of response) {
        if (r.walletAllocation) {
          r.walletAllocation.eligibleDate = r.walletAllocation.eligibleDate ? new Date(r.walletAllocation.eligibleDate) : undefined;
        }
      }
      return response;
    } catch {
      return [];
    }
  }

  async computeAllocationOld(EmployeeProfileEmail: string): Promise<number | undefined> {
    try {
      const response = await this.get<Compute>(`${this.apiv2Url}/employee/${EmployeeProfileEmail}/allocation/compute`);
      return response?.allocation;
    } catch {
      return undefined;
    }
  }

  async resetAllocation(EmployeeProfileEmail: string): Promise<number | undefined> {
    try {
      const response = await this.get<Compute>(`${this.apiv2Url}/employee/${EmployeeProfileEmail}/allocation/reset`);
      return response?.accountBalance;
    } catch {
      return undefined;
    }
  }

  async getEmployeeProfile(organizationId: number, employeeProfileId: string): Promise<EmployeeProfile | undefined> {
    const profile = await this.get<EmployeeProfile>(`${this.apiUrl}/organizations/${organizationId}/users/employeeProfiles/${employeeProfileId}`);
    if (!profile) return undefined;

    return {
      ...profile,
      employeeTypeDate: profile.employeeTypeDate ? dayjs(profile.employeeTypeDate) : undefined,
      statusDate: profile.statusDate ? dayjs(profile.statusDate) : undefined,
      hireDate: profile.hireDate ? dayjs(profile.hireDate) : undefined,
      payTypeDate: profile.payTypeDate ? dayjs(profile.payTypeDate) : undefined,
      jobCodeDate: profile.jobCodeDate ? dayjs(profile.jobCodeDate) : undefined,
    };
  }

  async updateEmployeeProfile(originalEmail: string, employeeProfile: EmployeeProfile): Promise<DemographicErrorResponse | undefined> {
    try {
      console.log(`updateEmployeeProfile(${originalEmail})`, employeeProfile);
      const data: UpdateEmployeeProfile = {
        email: employeeProfile.email,

        firstname: employeeProfile.firstname,
        lastname: employeeProfile.lastname,
        employeeNumber: employeeProfile.employeeNumber,

        companyCode: employeeProfile.companyCode,
        division: employeeProfile.division,
        employeeType: employeeProfile.employeeType,
        employeeTypeDate: employeeProfile.employeeTypeDate,
        jobCountry: employeeProfile.jobCountry,
        personalLocation: employeeProfile.personalLocation,
        status: employeeProfile.status,
        statusDate: employeeProfile.statusDate,
        hireDate: employeeProfile.hireDate,
        payType: employeeProfile.payType,
        payTypeDate: employeeProfile.payTypeDate,
        jobCode: employeeProfile.jobCode,
        jobCodeDate: employeeProfile.jobCodeDate,
        payGroup: employeeProfile.payGroup,
      };
      const result = await this.patch<UpdateEmployeeProfile>(`${this.apiv2Url}/user`, data);
      if (!result) return { code: "Unkown error", details: { fields: new Map<string, string>() }, message: "An unexpected error occured." };
      return undefined;
    } catch (error: any) {
      const errResponse = error?.response?.data?.response;
      if (errResponse) {
        if (errResponse.code) {
          return {
            code: errResponse.code,
            details: {
              fields: new Map(Object.entries(errResponse?.details.fields)),
            },
            message: errResponse.message,
          };
        } else {
          return { code: "Unknown", details: { fields: new Map<string, string>() }, message: errResponse };
        }
      }

      let demographicError: DemographicErrorResponse = { code: "Unknown", details: { fields: new Map<string, string>() }, message: "" };
      return demographicError;
    }
  }

  async importUsers(organizationId: number, executeImport: boolean, file: File): Promise<ImportResponse | ImportErrorResponse> {
    if (organizationId < 0) {
      throw new Error("Invalid organizationId.");
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await this.post<FormData, ImportResponse | undefined>(
        `${this.apiUrl}/organizations/${organizationId}/users/import?executeImport=${executeImport}`,
        formData
      );

      if (response) {
        return response;
      }

      throw new Error("Unexpected response format.");
    } catch (error: any) {
      if (error.response?.data?.code === "VALIDATION_ERROR") {
        return {
          code: error.response.data.code,
          message: error.response.data.message,
          details: error.response.data.details,
        };
      }

      throw error;
    }
  }
}

const userService = new UserService();
export default userService;
