import { Box, Button, Input, Paper, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import { ImportErrorResponse } from "../../services/Users/Models/ImportErrorResponse";
import ImportResponse from "../../services/Users/Models/ImportResponse";
import { Notify } from "../../components/notify";
import Organization from "../../services/Organizations/Organization";
import OrganizationSelector from "../../components/OrganizationSelector";
import RolesHelpers from "../../services/Users/RolesHelpers";
import { t } from "i18next";
import { useParams } from "react-router-dom";
import { useSessionContext } from "../../contexts/SessionContext";
import userService from "../../services/Users/UserService";

type ImportResult = ImportResponse | ImportErrorResponse | null;

export default function ImportUsersPage() {
  const { user } = useSessionContext();
  const workindAdmin = user && RolesHelpers.IsWorkindAdminFromList(user.roles);
  const [organizationSelected, setOrganizationSelected] = useState<Organization | undefined>(undefined);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [importResult, setImportResult] = useState<ImportResult>(null);
  const [disableImport, setDisableImport] = useState<boolean>(true);
  const { organizationId } = useParams();

  useEffect(() => {
    if (organizationId !== null && organizationId !== undefined) {
      const orgId = parseInt(organizationId);
      const org = user?.organizations.find((x) => x.id === orgId);
      if (org) {
        setOrganizationSelected(org);
      }
    }
    if (user && !workindAdmin && user.organizations.length > 0) {
      setOrganizationSelected(user.organizations[0]);
    }
  }, [user, organizationId]);

  function onOrganizationChange(orgId: number) {
    const org = user?.organizations.find((o) => o.id === orgId);
    setOrganizationSelected(org);
  }

  function handleFileUpload(event: React.ChangeEvent<HTMLInputElement>) {
    const file = event.target.files?.[0];
    if (file) {
      const isCSV = file.type === "text/csv" || file.name.endsWith(".csv");
      if (!isCSV) {
        Notify.error(t("import.msg-error-wrong-extension"));
        setSelectedFile(null);
        return;
      }
      setSelectedFile(file);
      console.log("File selected:", file.name);
    }
  }

  async function handleImport(executeImport: boolean) {
    if (!organizationSelected) {
      Notify.error(t("import.msg-error-select-org"));
      return;
    }

    if (!selectedFile) {
      Notify.error(t("import.msg-error-csvfile"));
      return;
    }

    try {
      const response = await userService.importUsers(organizationSelected.id, executeImport, selectedFile);

      setImportResult(response);

      if ("metadatas" in response) {
        Notify.success(t("import.msg-import-success"));
        setDisableImport(false);
      } else if ("code" in response) {
        Notify.error(t("import.msg-validation-error"));
      }
    } catch (err) {
      Notify.error(`${t("import.msg-error")}: ${err}`);
    }
  }

  return (
    <>
      <Typography variant="h2" mb={3} textAlign="left">
        {t("import.btn-import-csv")}
      </Typography>
      <Stack direction="column" alignItems="flex-start" spacing={2} mb={3}>
        {workindAdmin && <OrganizationSelector allowAllSelection={false} onChange={onOrganizationChange} value={organizationSelected?.id ?? -1} />}
      </Stack>
      <Paper elevation={3} sx={{ padding: 3, maxWidth: 500, margin: "0 auto", borderRadius: 2, textAlign: "left" }}>
        <form>
          <Box mb={2} sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
            <Input
              type="file"
              inputProps={{ accept: ".csv" }}
              onChange={handleFileUpload}
              fullWidth
              sx={{
                border: "1px solid #ccc",
                padding: "10px",
                borderRadius: 2,
                backgroundColor: "#f9f9f9",
              }}
            />
          </Box>
          <Stack direction="row" spacing={2} justifyContent="center" mt={2}>
            <Button variant="contained" color="primary" sx={{ padding: "10px" }} onClick={() => handleImport(false)}>
              {t("import.btn-upload-csv")}
            </Button>
            <Button variant="contained" sx={{ padding: "10px" }} color="primary" disabled={disableImport} onClick={() => handleImport(true)}>
              {t("import.btn-import-csv")}
            </Button>
          </Stack>
        </form>
      </Paper>

      {importResult && (
        <Paper elevation={3} sx={{ padding: 3, maxWidth: 500, margin: "20px auto", borderRadius: 2, backgroundColor: "#f4f7f6" }}>
          {"metadatas" in importResult ? (
            <>
              <Typography variant="h5" mb={2} color="primary" fontWeight="bold">
                {t("import.import-result")}
              </Typography>
              <Typography mb={1}>
                {t("import.records-created")}: {importResult.metadatas.created}
              </Typography>
              <Typography mb={1}>
                {t("import.records-updated")}: {importResult.metadatas.updated}
              </Typography>
              {importResult.importId && <Typography mb={2}>Import ID: {importResult.importId}</Typography>}
              <Typography variant="h6" mt={2} color="secondary" fontWeight="bold">
                Logs:
              </Typography>
              <Box sx={{ maxHeight: 350, overflowY: "auto", padding: 2, backgroundColor: "#fff", border: "1px solid #ccc", borderRadius: 2 }}>
                {importResult.logs.map((log, index) => (
                  <Typography key={index} color={log.level === "Error" ? "error" : log.level === "Warning" ? "warning" : "textPrimary"} mb={1}>
                    [{log.level}] {log.text}
                  </Typography>
                ))}
              </Box>
            </>
          ) : (
            <>
              <Typography variant="h5" mb={2} color="primary" fontWeight="bold">
                {t("import.validation-errors")}
              </Typography>
              <Box sx={{ maxHeight: 350, overflowY: "auto", padding: 2, backgroundColor: "#fff", border: "1px solid #ccc", borderRadius: 2 }}>
                {importResult.details.map((detail, index) => (
                  <Typography key={index} color="error" mb={1}>
                    {detail.message}
                  </Typography>
                ))}
              </Box>
            </>
          )}
        </Paper>
      )}
    </>
  );
}
