import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Typography,
} from "@mui/material";

interface AddressModalProps {
	open: boolean;
	title: string;
	content: string;
	buttonContent: string;
}

const AddressModal: React.FC<AddressModalProps> = ({
	open,
	title,
	content,
	buttonContent = "Ok",
}) => {
	const addressUrl = `${process.env.REACT_APP_PORTAL_URL}/account`;

	return (
		<Dialog
			open={open}
			PaperProps={{
				sx: {
					top: "150px",
					position: "fixed",
				},
			}}
		>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent>
				<Typography>{content}</Typography>
			</DialogContent>
			<DialogActions>
				<Button variant="contained" href={addressUrl} target="_parent">
					{buttonContent}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default AddressModal;
