import { useEffect, useState, useMemo } from "react";

import { ThemeProvider } from "@emotion/react";
import { makeStyles } from "@material-ui/core";
import { CircularProgress, Grid, Typography, Link } from "@mui/material";
import { Box, Container, Stack } from "@mui/system";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import i18n from "../../services/i18n";
import Category from "../../services/Marketplace/Category";
import marketplaceService from "../../services/Marketplace/MarketplaceService";
import OrganizationHelpers from "../../services/Organizations/OrganizationHelpers";
import WalletLink from "../../services/Organizations/WalletLink";
import userService from "../../services/Users/UserService";
import theme from "../../theme/main-theme";
import { LoadingStatus } from "../../contexts/LoadingStatus";
import { useSessionContext } from "../../contexts/SessionContext";
import { Notify } from "../../components/notify";
import AllocationComponent from "../../components/AllocationComponent";
import ServicesList from "../../userWallet/components/ServicesList";
import AddressModal from "../../components/AddressModal";

const useStyles = makeStyles({
  container: {
    [theme.breakpoints.up("lg")]: {
      padding: 0,
    },
  },
  heading: {
    marginBottom: "2rem",
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.primary.main,
    fontSize: 35,
    letterSpacing: "-1px",
  },
  backBtn: {
    display: "flex",
    gap: "10px",
    fontSize: "20px",
    alignItems: "center",
    lineHeight: "1",
    marginBottom: "8px",
  },
  backBtnSpan: {
    display: "flex",
    width: "19px",
    height: "19px",
    borderRadius: "50%",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "hsla(169, 63%, 40%, 1)",
  },
});

export default function WalletPage() {
  const { t } = useTranslation();
  const { walletId } = useParams<string>();
  const { user } = useSessionContext();
  const classes = useStyles();

  const reimbursementUrl = `${userService.appPortalUrl}/cascade-refunds?orgWalletID=${walletId}`;
  const [loading, setLoading] = useState<LoadingStatus>(LoadingStatus.Loading);
  const [links, setLinks] = useState<WalletLink[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);

  const orgWallet =
    user && walletId
      ? OrganizationHelpers.getOrganizationWallet(
          user.organizations,
          user.user.organizationId,
          walletId
        )
      : undefined;
  const wallet = user?.user.wallets.find(
    (wallet) => wallet.organizationWalletId === walletId
  );
  const walletTitle = user?.organizations
    .flatMap(({ wallets }) => wallets)
    .find(({ id }) => id === walletId)?.display[i18n.language];

  const [showModal, setShowModal] = useState(false);
  const [willRedirect, setWillRedirect] = useState(false);

  const hasAddress = useMemo(() => {
    const address = user?.user.addresses?.[0];
    return address?.address && address?.city && address?.postalCode;
  }, [user]);

  useEffect(() => {
    if (hasAddress) {
      return;
    }

    setShowModal(true);
  }, [hasAddress]);

  useEffect(() => {
    async function get() {
      try {
        if (user && walletId) {
          setLoading(LoadingStatus.Loading);

          const links = await userService.getWalletLinks(walletId);
          const categories = await marketplaceService.getWalletCategories(
            user.user.organizationId,
            walletId,
            user.user.addresses[0] && user.user.addresses[0].postalCode
          );

          if (categories.length === 0 && links.length === 0) {
            setWillRedirect(true);
            window.open(`${reimbursementUrl}`, "_parent");
          }

          setLinks(links);
          setCategories(categories);
          setLoading(LoadingStatus.Success);
        }
      } catch (e) {
        setLoading(LoadingStatus.Failure);
        Notify.error(t("walletPage.error-loading"));
      }
    }

    get();
  }, [t, user, walletId, reimbursementUrl]);

  const isMultiWallets =
    user?.user && user.user.wallets.length > 1 ? true : false;
  const backLink = isMultiWallets ? "/wallets" : "/home";
  const backLabel = isMultiWallets
    ? t("dashboard.userPage.wallets-label")
    : t("dashboard.userPage.wallet-label");

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="lg" className={classes.container}>
        {loading === LoadingStatus.Loading || willRedirect ? (
          <div
            style={{
              minHeight: "500px",
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Stack justifyContent="center" direction="row">
              <CircularProgress />
            </Stack>
          </div>
        ) : (
          <>
            <AddressModal
              open={showModal}
              title={t("dashboard.adressModal.title")}
              content={t("dashboard.adressModal.content")}
              buttonContent={t("dashboard.adressModal.title")}
            />

            <Box
              pt={{ xs: "40px", sm: "40px", md: "80px" }}
              px={{ sx: "16px", lg: "0" }}
            >
              <Box className={`boutique-back-btn ${classes.backBtn}`}>
                <span className={classes.backBtnSpan}>
                  <svg
                    width="6"
                    height="9"
                    viewBox="0 0 6 9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.12037 7.96718L2.0432 4.50216L5.12038 1.03259C5.21316 0.9229 5.26408 0.783875 5.26408 0.640202C5.26408 0.496529 5.21316 0.357504 5.12038 0.24781C5.07774 0.196649 5.02437 0.155487 4.96406 0.12724C4.90374 0.0989926 4.83795 0.0843505 4.77135 0.0843505C4.70475 0.0843505 4.63897 0.0989926 4.57865 0.12724C4.51834 0.155487 4.46497 0.196649 4.42233 0.24781L0.994071 4.10853C0.900551 4.21836 0.849191 4.3579 0.849191 4.50216C0.849191 4.64641 0.900551 4.78595 0.994071 4.89579L4.42233 8.75651C4.46497 8.80767 4.51834 8.84883 4.57865 8.87708C4.63897 8.90532 4.70475 8.91997 4.77135 8.91997C4.83795 8.91997 4.90374 8.90532 4.96405 8.87708C5.02437 8.84883 5.07773 8.80767 5.12037 8.75651C5.21429 8.64645 5.26588 8.50652 5.26588 8.36184C5.26588 8.21717 5.21429 8.07723 5.12037 7.96718Z"
                      fill="white"
                    />
                  </svg>
                </span>
                <Link href={backLink}>
                  {t("dashboard.userPage.wallets-label")}
                </Link>
              </Box>
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
                pb="50px"
                gap="25px"
              >
                <Box>
                  <Typography variant="h2">{t("walletPage.header")}</Typography>
                  <Typography variant="h1">{walletTitle}</Typography>
                </Box>
                <AllocationComponent
                  withoutAllocation={orgWallet?.withoutAllocation ?? false}
                  allocation={wallet?.allocation || 0}
                  balance={wallet?.balance || 0}
                  link={{
                    url: reimbursementUrl,
                    label: t("walletPage.refund"),
                  }}
                />
              </Grid>

              <Typography className={classes.heading} variant="h3">
                {t("walletPage.services")}
              </Typography>

              <ServicesList categories={categories} links={links} />
            </Box>
          </>
        )}
      </Container>
    </ThemeProvider>
  );
}
